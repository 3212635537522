import React from "react";

export default class RAMToFRAMItemComponent extends React.Component {

    render() {
        const {value} = this.props;
        return (
            <div>
                {value.map((val, index) =>
                    <div key={index}>
                        {index + 1}: <i className={`fas ${val ? "fa-check" : "fa-times"}`}/>
                    </div>
                )}
            </div>
        );
    }

}