import React from "react";
import Card from "../../../../components/basics/card/Card";
import devicesDB from "../../../../database/devicesDB";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import LabeledSelect from "../../../../components/basics/select/labeled-select/LabeledSelect";
import NewIOT from "../../../../IOT/NewIOT";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import {Col, Row} from "react-bootstrap";
import _ from "lodash"
import moment from "moment";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {getNRFStats} from "../../../../IOT/device-functions/GatewayFunctions";
import Button from "../../../../components/basics/button/Button";
import {
    getB485ToutR,
    getBBootInfo,
    getBBootInfoNRF,
    getBInfo,
    getBNRFStat,
    getBScanAddr
} from "../../../../IOT/device-functions/BridgeFunctions";

export class BridgeData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bridges: devicesDB.getDevices(props.farm).filter(item => item.DevType === DevType.BRIDGE_CONF),
            bridge: null,
            loading: false,
            worktime: 0,
            interval: null,
            stats: [],
            b_info: null,
            boot_info: null,
            boot_info_nrf: null,
            nrf_stat: null,
            rs485_tout: [],
            scan_addr: []
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {bridge} = this.state;
        if (bridge) {
            const {shadows} = nextProps;
            let shadow = shadows.get(bridge.DevID);
            let oldShadow = this.props.shadows.get(bridge.DevID);
            if (shadow && !_.isEqual(shadow, oldShadow)) {
                this.setState({
                    ...shadow,
                    ...this.getTimer(shadow.b_info)
                })
            }
        }
    }

    getTimer(b_info) {
        const {worktime} = this.state;
        let wt = _.get(b_info, "work_time");
        if (worktime !== wt) {
            clearInterval(this.state.interval);
            let interval = setInterval(() => {
                wt += 1000;
                this.setState({
                    worktime: wt
                })
            }, 1000);
            return {
                worktime: wt,
                interval
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    turnOffLoading = () => {
        this.setState({
            loading: false
        })
    };

    onGetNRFStatsSuccess = msg => {
        console.log("SUCCESS");
        try {
            this.setState({
                stats: msg.CAnsw.stats
            })
        } catch (e) {
            console.error(e);
        }
    };

    onGetNRFStatsFailure = () => {
        this.setState({
            stats: []
        })
    }

    onSelectChange = value => {
        const {shadows} = this.props;
        this.setState({
            bridge: value,
            loading: !shadows.get(value.DevID)
        });

        NewIOT.startSendingDeviceState(value, this.turnOffLoading, this.turnOffLoading);
        getNRFStats(value.GatewayID, value.DevID, this.onGetNRFStatsSuccess, this.onGetNRFStatsFailure);
    };

    getWorkTime() {
        try {
            const {worktime} = this.state;
            let s = Math.floor((worktime / 1000) % 60);
            let m = Math.floor((worktime / (1000 * 60)) % 60);
            let h = Math.floor((worktime / (1000 * 60 * 60)) % 24);
            let d = Math.floor((worktime / (1000 * 60 * 60 * 24)))
            let seconds = (s < 10) ? "0" + s : s;
            let minutes = (m < 10) ? "0" + m : m;
            let hours = (h < 10) ? "0" + h : h;
            let days = (d < 10) ? "0" + d : d;
            return days + ":" + hours + ":" + minutes + ":" + seconds;
        } catch (e) {
            return "-";
        }
    }

    getNRFData() {
        const {nrf_stat} = this.state;
        try {
            return nrf_stat.map((stat, index) => ({
                name: index === 0 ? "Lewy" : index === 1 ? "Centralny" : "Prawy",
                channel: stat.NRF24_nrch,
                power: stat.NRF24_outPower,
                received: stat.packets_RX,
                send: stat.packets_ok,
                repetitions: stat.packets_rep,
                lost: stat.packets_lost,
                ready: stat.nrf_ready === 0
            }))
        } catch (e) {
            return [];
        }
    }

    getNRFStats() {
        try {
            const {stats} = this.state;
            let nrfStats = [];
            for (let stat of stats) {
                for (let device of stat.devices) {
                    if (device.address) {
                        nrfStats.push({
                            address: `0x${device.address.toString(16).toUpperCase()}00`,
                            received: device.counter_RX,
                            send: device.counter_TX,
                            repetitions: device.counter_rep,
                            lost: device.counter_lost,
                            status: device.stat
                        })
                    }
                }
            }
            return nrfStats;
        } catch (e) {
            return [];
        }
    }

    onGetBInfoSuccess = msg => {
        const {bridge} = this.state;
        const b_info = msg.CAnsw[bridge.DevID].b_info;
        this.setState({
            b_info,
            ...this.getTimer(b_info)
        })
    };

    getStandardInfo = () => {
        const {bridge} = this.state;
        getBInfo(bridge, this.onGetBInfoSuccess);
    };

    onGetBBootInfoSuccess = msg => {
        const {bridge} = this.state;
        this.setState({
            boot_info: msg.CAnsw[bridge.DevID].boot_info
        })
    };

    onGetBBootInfoNRFSuccess = msg => {
        const {bridge} = this.state;
        this.setState({
            boot_info_nrf: msg.CAnsw[bridge.DevID].boot_info_nrf
        })
    };

    getProgrammInfo = () => {
        const {bridge} = this.state;
        getBBootInfo(bridge, this.onGetBBootInfoSuccess);
        getBBootInfoNRF(bridge, this.onGetBBootInfoNRFSuccess)
    };

    getBNRFStartSuccess = msg => {
        const {bridge} = this.state;
        this.setState({
            nrf_stat: msg.CAnsw[bridge.DevID].nrf_stat
        })
    };

    getNRFInfo = () => {
        const {bridge} = this.state;
        getBNRFStat(bridge, this.getBNRFStartSuccess)
    };

    onGetB485TourRSuccess = msg => {
        const {bridge} = this.state;
        let number = msg.CData.nr;
        let data = _.cloneDeep(msg.CAnsw[bridge.DevID].rs485_tout[number]);
        const rs485_tout = _.cloneDeep(this.state.rs485_tout) || [];
        rs485_tout[number] = data;
        this.setState({
            rs485_tout
        })
    };

    getRSInfo = () => {
        const {bridge} = this.state;
        getB485ToutR(bridge, 0, this.onGetB485TourRSuccess);
        getB485ToutR(bridge, 1, this.onGetB485TourRSuccess);
    };

    getNRFStatsButton = () => {
        const {bridge} = this.state;
        getNRFStats(bridge.GatewayID, bridge.DevID, this.onGetNRFStatsSuccess, this.onGetNRFStatsFailure);
    };

    onGetBScanAddrSuccess = msg => {
        const {bridge} = this.state;
        this.setState({
            scan_addr: msg.CAnsw[bridge.DevID].scan_addr
        })
    };

    getScanAddresses = () => {
        const {bridge} = this.state;
        getBScanAddr(bridge, 0, this.onGetBScanAddrSuccess);
        getBScanAddr(bridge, 1, this.onGetBScanAddrSuccess);
        getBScanAddr(bridge, 2, this.onGetBScanAddrSuccess);
    };

    getScanAddressData() {
        const {scan_addr} = this.state;
        try {
            let data = [];
            let address = 0x11;
            const nrf0Array = scan_addr[0].addresses;
            const nrf1Array = scan_addr[1].addresses;
            const nrf2Array = scan_addr[2].addresses;
            // tablice maja taka sama dlugosc (105 el.)
            for (let i = 0; i < nrf0Array.length; i++) {
                let nrf0 = nrf0Array[i];
                let nrf1 = nrf1Array[i];
                let nrf2 = nrf2Array[i];
                // nie ma adresow typu 0x2100 wiec przeskakiwany jest jeden krok
                if (i !== 0 && i % 7 === 0) {
                    address += 2;
                }
                address += 2;
                data.push({
                    address: `0x${address.toString(16).padEnd(4, "0").toUpperCase()}`,
                    nrf0,
                    nrf1,
                    nrf2
                })
            }
            return data;
        } catch (e) {
            return [];
        }
    }

    render() {
        const {bridges, bridge, loading, b_info, boot_info, boot_info_nrf, rs485_tout} = this.state;
        let headers = [
            {
                name: "",
                field: "name"
            },
            {
                name: "Kanał",
                field: "channel"
            },
            {
                name: "Moc",
                field: "power"
            },
            {
                name: "Odebrane",
                field: "received"
            },
            {
                name: "Wysłane",
                field: "send"
            },
            {
                name: "Powtórki",
                field: "repetitions"
            },
            {
                name: "Utracone",
                field: "lost"
            },
            {
                name: "Gotowy",
                field: "ready",
                component: props => <i className={!props.value ? "fas fa-times" : "fas fa-check"}/>
            }
        ];
        let headersNRF = [
            {
                name: "Adres",
                field: "address"
            },
            {
                name: "Odebrane",
                field: "received"
            },
            {
                name: "Wysłane",
                field: "send"
            },
            {
                name: "Powtórzenia",
                field: "repetitions"
            },
            {
                name: "Utracone",
                field: "lost"
            },
            {
                name: "Status",
                field: "status"
            }
        ];
        const scanAddressHeaders = [
            {
                name: "Adres",
                field: "address"
            },
            {
                name: "Lewo",
                field: "nrf0"
            },
            {
                name: "Środek",
                field: "nrf1"
            },
            {
                name: "Prawo",
                field: "nrf2"
            }
        ];
        return (
            <>
                <Card>
                    <LabeledSelect label="Bridge" options={bridges.map(bridge => ({
                        name: bridge.Name,
                        value: bridge
                    }))} value={bridge} onChange={this.onSelectChange} clearButton={false}/>
                </Card>
                {
                    bridge &&
                    <Card>
                        <LoadingComponent isLoading={loading}/>
                        <h4 className="d-flex justify-content-between">
                            Podstawowe informacje
                            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                    onClick={this.getStandardInfo}/>
                        </h4>
                        <Row>
                            <Col xl={6}>
                                <div>
                                    <strong>Nazwa:</strong> {_.get(b_info, "name_prg_text", "-")}
                                </div>
                                <div>
                                    <strong>Serial:</strong> {_.get(b_info, "serial", "-")}
                                </div>
                                <div>
                                    <strong>Czas
                                        restartu:</strong> {moment(_.get(b_info, "Lstart_time", 0)).format("DD.MM.YYYY HH:mm")}
                                </div>
                                <div>
                                    <strong>Czas pracy:</strong> {this.getWorkTime()}
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div>
                                    <strong>Wersja:</strong> {_.get(b_info, "ver_prg", "-")}
                                </div>
                                <div>
                                    <strong>Wersja HAL:</strong> {_.get(b_info, "HAL_VERSION", "-")}
                                </div>
                                <div>
                                    <strong>Aktualny
                                        czas:</strong> {moment(_.get(b_info, "Lact_time", 0)).format("DD.MM.YYYY HH:mm")}
                                </div>
                                <div>
                                    <strong>Flaga RCC:</strong> {_.get(b_info, "RCC_FLAG", "-")}
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <h4 className="d-flex justify-content-between">
                            Informacje o programach
                            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                    onClick={this.getProgrammInfo}/>
                        </h4>
                        <Row>
                            <Col md={4}>
                                <h6>Program aktualny</h6>
                                <div>
                                    <strong>Stan:</strong> {_.get(boot_info, "act_info.state_prg", "-")}
                                </div>
                                <div>
                                    <strong>Wersja:</strong> {_.get(boot_info, "act_info.ver_prg", "-")}
                                </div>
                                <div>
                                    <strong>Rozmiar:</strong> {_.get(boot_info, "act_info.prg_size", "-")}
                                </div>
                                <div>
                                    <strong>Nazwa:</strong> {_.get(boot_info, "act_info.name_prg", "-")}
                                </div>
                            </Col>
                            <Col md={4}>
                                <h6>Program w pamięci</h6>
                                <div>
                                    <strong>Stan:</strong> {_.get(boot_info, "prg_info.state_prg", "-")}
                                </div>
                                <div>
                                    <strong>Wersja:</strong> {_.get(boot_info, "prg_info.ver_prg", "-")}
                                </div>
                                <div>
                                    <strong>Rozmiar:</strong> {_.get(boot_info, "prg_info.prg_size", "-")}
                                </div>
                                <div>
                                    <strong>Nazwa:</strong> {_.get(boot_info, "prg_info.name_prg", "-")}
                                </div>
                            </Col>
                            <Col md={4}>
                                <h6>Program NRF</h6>
                                <div>
                                    <strong>Stan:</strong> {_.get(boot_info_nrf, "prg_info.state_prg", "-")}
                                </div>
                                <div>
                                    <strong>Wersja:</strong> {_.get(boot_info_nrf, "prg_info.ver_prg", "-")}
                                </div>
                                <div>
                                    <strong>Rozmiar:</strong> {_.get(boot_info_nrf, "prg_info.prg_size", "-")}
                                </div>
                                <div>
                                    <strong>Nazwa:</strong> {_.get(boot_info_nrf, "prg_info.name_prg", "-")}
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <h4 className="d-flex justify-content-between">
                            Informacje o NRF
                            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                    onClick={this.getNRFInfo}/>
                        </h4>
                        <TableGrid data={this.getNRFData()} headers={headers}/>
                        <hr/>
                        <h4 className="d-flex justify-content-between">
                            Interfejsy RS
                            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                    onClick={this.getRSInfo}/>
                        </h4>
                        {
                            (rs485_tout || []).map(tout =>
                                <Row>
                                    <Col md={4}>
                                        <strong>Nr:</strong> {tout.rs}
                                    </Col>
                                    <Col md={4}>
                                        <strong>tout:</strong> {tout.tout}
                                    </Col>
                                    <Col md={4}>
                                        <strong>set:</strong> {tout.set}
                                    </Col>
                                </Row>
                            )
                        }
                        <hr/>
                        <h4 className="d-flex justify-content-between">
                            Skanowane adresy
                            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                    onClick={this.getScanAddresses}/>
                        </h4>
                        <TableGrid data={this.getScanAddressData()} headers={scanAddressHeaders}/>
                        <hr/>
                        <h4 className="d-flex justify-content-between">
                            Statystyki
                            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                    onClick={this.getNRFStatsButton}/>
                        </h4>
                        <TableGrid data={this.getNRFStats()} headers={headersNRF}/>
                    </Card>
                }
            </>
        );
    }

}

BridgeData = connect(state => ({
    farm: state.location.farm,
    shadows: state.shadows.shadows
}))(BridgeData);

export default BridgeData;