import React from "react";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import Card from "../../../../components/basics/card/Card";
import Select from "../../../../components/basics/select/Select";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import NewIOT from "../../../../IOT/NewIOT"
import ReactJson from "react-json-view"

export class Shadows extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectValue: undefined,
            options: props.devices.filter(item => item.DevType !== DevType.GATEWAY && item.DevType !== DevType.BRIDGE && item.DevType !== DevType.SMALL_CAGE).map(dev => ({
                name: dev.getSelectName(),
                value: dev
            }))
        }
    }

    onChangeDevice = value => {
        this.setState({
            selectValue: value
        });
        NewIOT.startSendingDeviceState(value);
    };

    getShadow() {
        const {shadows} = this.props;
        const {selectValue} = this.state;
        try {
            return shadows.get(selectValue.DevID);
        } catch (e) {
            return null;
        }
    }

    render() {
        const {selectValue, options} = this.state;
        let shadow = this.getShadow();
        return (
            <Card>
                <Select value={selectValue} options={options} onChange={this.onChangeDevice}/>
                {
                    selectValue &&
                    <div>
                        <ReactJson src={shadow} name={selectValue.Name} collapsed={1} theme={{
                            base00: "white",
                            base01: "#000000",
                            base02: "#000000",
                            base03: "#000000",
                            base04: "purple",
                            base05: "#000000",
                            base06: "#000000",
                            base07: "#000000",
                            base08: "#000000",
                            base09: "#000000",
                            base0A: "#000000",
                            base0B: "#ffc007",
                            base0C: "#000000",
                            base0D: "#33cc33",
                            base0E: "#fd3b60",
                            base0F: "#00afff"
                        }}/>
                    </div>
                }
            </Card>
        );
    }

}

export let _Shadows = connect(state => ({
    devices: state.farmDevices.devices,
    shadows: state.shadows.shadows
}))(Shadows);
export default withRoles({roles: [Roles._SERVICE], showComponent: true})(_Shadows);
