import React from "react";
import devicesDB from "../../../../database/devicesDB";
import {DevType} from "../../../../constans/devices";
import NewIOT from "../../../../IOT/NewIOT";
import Card from "../../../../components/basics/card/Card";
import _ from "lodash";
import Button from "../../../../components/basics/button/Button";
import {getBBootInfoNRF, setBSendDozoPRG} from "../../../../IOT/device-functions/BridgeFunctions";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {ProgramButtonRenderer} from "./ProgramButtonRenderer";
import "./_programs.scss"
import Menu from "../../../../components/basics/menu/Menu";
import MenuItem from "../../../../components/basics/menu/MenuItem";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import {setControlBootloader} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../components/modals-new/confirm-modal/ConfirmModal";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";
import {BridgeCommandTypes, DispenserNRFCommandTypes} from "../../../../constans/mqttMessages";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

export class Programs extends React.Component {

    constructor(props) {
        super(props);

        const {farm} = this.props;
        let allDevices = devicesDB.getDevices(farm);
        let bridges = allDevices.filter(item => item.DevType === DevType.BRIDGE);
        let multis = allDevices.filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI);
        let confs = allDevices.filter(item => item.DevType === DevType.BRIDGE_CONF);
        let array = [];
        for (let bridge of bridges) {
            let multisConnectedToBridge = multis.filter(item => item.ParentID === bridge.DevID);
            if (multisConnectedToBridge.length > 0) {
                let conf = confs.find(item => item.ParentID === bridge.DevID);
                if (conf) {
                    array.push({
                        bridgeConf: conf,
                        multis: multisConnectedToBridge
                    })
                }
            }
        }
        let devicesForShadow = [];
        for (let row of array) {
            devicesForShadow = [...devicesForShadow, row.bridgeConf, ...row.multis];
        }
        NewIOT.startSendingDeviceState(devicesForShadow);
        this.state = {
            data: array,
            event: [],
            eventControlBootloader: []
        }
    }

    addressValueFormatter = value => {
        return `0x${value.toString(16).padStart(4, "0").toUpperCase()}`
    };

    shadowValueFormatter(devID, path) {
        const {shadows} = this.props;
        let shadow = shadows.get(devID);
        let value = _.get(shadow, path, "");
        if (Array.isArray(value)) return value.join("/");
        return value;
    }

    onSendDozoPrgClick(e, index) {
        let event = this.state.event.slice(0);
        event[index] = e;
        this.setState({
            event
        });
    };

    restartByBroadcasts = () => {
        const {farm} = this.props;
        let broadcasts = devicesDB.getDevices(farm).filter(item => item.DevType === DevType.BROADCAST);
        console.log(broadcasts);
        for (let broadcast of broadcasts) {
            setControlBootloader(broadcast, 3);
        }
    }

    onRestartClick = () => {
        const {data} = this.state;
        const {shadows} = this.props;
        let diffVersions = [];
        for (let row of data) {
            let confShadow = shadows.get(row.bridgeConf.DevID);
            let bridgeVersion = _.get(confShadow, "boot_info_nrf.prg_info.ver_prg");
            for (let multi of row.multis) {
                let multiShadow = shadows.get(multi.DevID);
                let multiVersion = _.get(multiShadow, "versions[1]");
                if (multiVersion !== bridgeVersion) {
                    diffVersions.push(multi);
                }
            }
        }
        this.props.show(ConfirmModalName, {
            title: "Restart dozowników",
            text: "Czy na pewno chcesz zrestartować dozowniki?",
            confirmText: "Tak",
            onConfirmed: props => {
                this.restartByBroadcasts();
                props.handleHide();
            },
            children: (
                <>
                    {
                        diffVersions.length > 0 &&
                        <div>
                            Następujące dozowniki mają niezaktualizowaną wersje oprogramowania:
                            {
                                diffVersions.map((multi, index) =>
                                    <div key={index}>{multi.Name}</div>
                                )
                            }
                        </div>
                    }
                </>
            )
        })
        // this.restartByBroadcasts();
    };

    onSetControlBootloader(e, index) {
        let eventControlBootloader = this.state.eventControlBootloader.slice(0);
        eventControlBootloader[index] = e;
        this.setState({
            eventControlBootloader
        })
    };

    onSetControlBootloaderMenuClick(bridge, interf) {
        const {farm} = this.props;
        let broadcast = devicesDB.getDevices(farm).find(item => item.DevType === DevType.BROADCAST && item.ParentID === bridge.ParentID && item.Interface === interf);
        if (broadcast) {
            setControlBootloader(broadcast, 3);
        }
    }

    render() {
        console.log(this.state);
        const {shadows} = this.props;
        const {data, event, eventControlBootloader} = this.state;
        const headers = [
            {
                name: "Nazwa",
                field: "Name"
            },
            {
                name: "Adres",
                field: "Address",
                valueFormatter: this.addressValueFormatter
            },
            {
                name: "Flaga",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "flags[1]")
            },
            {
                name: "Liczniki",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "counters")
            },
            {
                name: "Wersja",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "versions[1]")
            },
            {
                name: "",
                headerClassName: "index",
                itemClassName: "index",
                component: <ProgramButtonRenderer/>
            }
        ];
        return (
            <Card className="programs">
                {
                    data.map((row, index) => {
                        let confShadow = shadows.get(row.bridgeConf.DevID);
                        return (
                            <div key={index}>
                                <h2>{row.bridgeConf.Name}</h2>
                                <h4 className="justify-content-between">
                                    Program NRF
                                    <div>
                                        <Tooltip tooltipContent={BridgeCommandTypes.B_BOOT_INFO_NRF}>
                                            <div className="d-inline-block">
                                                <Button buttonStyle={"round"}
                                                        icon={<i className="fas fa-arrow-alt-circle-down"/>}
                                                        onClick={() => getBBootInfoNRF(row.bridgeConf)}/>
                                            </div>
                                        </Tooltip>
                                        <Tooltip tooltipContent={`${DispenserNRFCommandTypes.SET_CONTROL_BOOTLOADER}(3)`}>
                                            <div className="d-inline-block">
                                                <Button buttonStyle={"round"} buttonColor={"error"}
                                                        icon={<i className="fas fa-redo"/>}
                                                        onClick={e => this.onSetControlBootloader(e, index)}/>
                                            </div>
                                        </Tooltip>
                                        <Tooltip tooltipContent={BridgeCommandTypes.SET_B_SEND_DOZO_PRG}>
                                            <div className="d-inline-block">
                                                <Button buttonStyle={"round"} buttonColor={"success"}
                                                        icon={<i className="fas fa-share"/>}
                                                        onClick={e => this.onSendDozoPrgClick(e, index)}/>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Menu event={event[index]}>
                                        <MenuItem onClick={() => setBSendDozoPRG(row.bridgeConf, 0)}>Lewy</MenuItem>
                                        <MenuItem onClick={() => setBSendDozoPRG(row.bridgeConf, 1)}>Środkowy</MenuItem>
                                        <MenuItem onClick={() => setBSendDozoPRG(row.bridgeConf, 2)}>Prawy</MenuItem>
                                    </Menu>
                                    <Menu event={eventControlBootloader[index]}>
                                        <MenuItem onClick={() => this.onSetControlBootloaderMenuClick(row.bridgeConf, 5)}>Lewy</MenuItem>
                                        <MenuItem onClick={() => this.onSetControlBootloaderMenuClick(row.bridgeConf, 7)}>Środkowy</MenuItem>
                                        <MenuItem onClick={() => this.onSetControlBootloaderMenuClick(row.bridgeConf, 6)}>Prawy</MenuItem>
                                    </Menu>
                                </h4>
                                <div>
                                    <strong>Stan:</strong> {_.get(confShadow, "boot_info_nrf.prg_info.state_prg", "-")}
                                </div>
                                <div>
                                    <strong>Wersja:</strong> {_.get(confShadow, "boot_info_nrf.prg_info.ver_prg", "-")}
                                </div>
                                <div>
                                    <strong>Rozmiar:</strong> {_.get(confShadow, "boot_info_nrf.prg_info.prg_size", "-")}
                                </div>
                                <div>
                                    <strong>Nazwa:</strong> {_.get(confShadow, "boot_info_nrf.prg_info.name_prg", "-")}
                                </div>
                                <TableGrid data={row.multis} headers={headers}/>
                                <hr/>
                            </div>
                        )
                    })
                }
                <ButtonGroup fixed>
                    <Tooltip tooltipContent={`${DispenserNRFCommandTypes.SET_CONTROL_BOOTLOADER}(3)`}>
                        <div className="d-inline-block">
                            <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-redo"/>}
                                    onClick={this.onRestartClick}/>
                        </div>
                    </Tooltip>
                </ButtonGroup>
            </Card>
        )
            ;
    }

}

Programs = connect(
    state => ({
        farm: state.location.farm,
        shadows: state.shadows.shadows
    }),
    dispatch => bindActionCreators({show}, dispatch)
)(Programs);

export default Programs;