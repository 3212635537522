import React from "react";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import Card from "../../../../components/basics/card/Card";
import {connect} from "react-redux";
import TerminalForm from "../../../../components/terminal-form/TerminalForm";

export class Terminal extends React.Component {


    render() {
        return (
            <Card>
              <TerminalForm/>
            </Card>
        );
    }

}

export let _Terminal = connect(state => ({}))(Terminal);
export default withRoles({roles: [Roles._SERVICE], showComponent: true})(_Terminal);
