import React from "react";
import Button from "../../../../components/basics/button/Button";
import {setControlBootloader} from "../../../../IOT/device-functions/DispenserNRFFunctions";

export default class CurrentProgramsButtonComponent extends React.Component {

    onButtonClick = () => {
        const {value} = this.props;
        setControlBootloader(value.multi, 2);
    };

    render() {
        return (
            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                    onClick={this.onButtonClick} style={{marginBottom: 0}}/>
        );
    }

}