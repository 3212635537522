import React from "react";
import Button from "../../../../components/basics/button/Button";
import {getUploadSoftwareStatus} from "../../../../IOT/device-functions/DispenserNRFFunctions";

export class ProgramButtonRenderer extends React.Component {

    onClick = () => {
        getUploadSoftwareStatus(this.props.value);
    }

    render() {
        return (
            <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>} onClick={this.onClick}/>
        );
    }

}