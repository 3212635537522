import React from "react";
import Card from "../../../../../components/basics/card/Card";
import devicesDB from "../../../../../database/devicesDB";
import {connect} from "react-redux";
import {DevType} from "../../../../../constans/devices";
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import NewIOT from "../../../../../IOT/NewIOT";
import _ from "lodash"
import moment from "moment";
import NRFDataButtonComponent from "./NRFDataButtonComponent";
import Button from "../../../../../components/basics/button/Button";
import {
    getCANStatus,
    getConfiguration,
    getCurveCRC,
    getFeedEfcImpulse,
    getFeedEfcTime,
    getScheduleCRC,
    getUploadSoftwareStatus
} from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import "./_nrf-data.scss"
import NRFEfficiencyComponent from "./NRFEfficiencyComponent";
import NRFDataStatusCANComponent from "./NRFDataStatusCANComponent";
import NRFDataHeaderComponent from "./NRFDataHeaderComponent";

export class NRFData extends React.Component {

    constructor(props) {
        super(props);

        let devices = devicesDB.getDevices(props.farm).filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI);
        for (let device of devices) {
            NewIOT.startSendingDeviceState(device);
        }

        this.state = {
            data: devices.map(device => ({device, flags: [], counters: [], versions: [], startTime: 0, curvesCRC: [], schedulesCRC: [], efficiency: [], statusCAN: {status: [], time: []}})),
            devices
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.shadows, nextProps.shadows)) {
            let data = [];
            for (let d of this.state.data) {
                let shadow = nextProps.shadows.get(d.device.DevID);
                data.push({
                    ...d,
                    flags: _.get(shadow, "flags", []),
                    counters: _.get(shadow, "counters", []),
                    versions: _.get(shadow, "versions", []),
                    startTime: _.get(shadow, "config.timeStart", 0),
                    curvesCRC: _.get(shadow, "curveCRC", []),
                    schedulesCRC: _.get(shadow, "scheduleCRC", []),
                    efficiency: _.get(shadow, "efficiencies", []).map(item => {
                        let status = _.get(shadow, "efficiencyStatuses", []).find(s => s.nr === item.nr) || {};
                        return {
                            ...item,
                            ...status
                        }
                    }),
                    statusCAN: _.get(shadow, "statusCAN", {status: [], time: []})
                })
            }
            this.setState({
                data
            })
        }
    }

    addressValueFormatter = value => {
        return "0x" + value.toString(16).toUpperCase();
    };

    dateValueFormatter = value => {
        return moment(value).format("DD.MM.YYYY HH:mm");
    };

    onGetAllClick = () => {
        let devices = devicesDB.getDevices(this.props.farm).filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI);
        for (let device of devices) {
            getUploadSoftwareStatus(device);
            getConfiguration(device);
            getCurveCRC(device);
            getScheduleCRC(device);
            getFeedEfcTime(device);
            getFeedEfcImpulse(device);
            getCANStatus(device);
        }
    };

    arrayValueFormatter = value => {
        try {
            if (value[0] !== undefined && value[1] !== undefined)
                return `${value[0]}/${value[1]}`
            return "";
        } catch (e) {
            return "";
        }
    };

    joinArrayValueFormatter = value => {
        try {
            return value.join(", ");
        } catch (e) {
            return "";
        }
    };

    getCanStatusForAllDevices = () => {
        const {devices} = this.state;
        for (let device of devices) {
            getCANStatus(device);
        }
    };

    getEfficiencyForAllDevices = () => {
        const {devices} = this.state;
        for (let device of devices) {
            getFeedEfcTime(device);
            getFeedEfcImpulse(device);
        }
    };

    getScheduleCRCForAllDevices = () => {
        const {devices} = this.state;
        for (let device of devices) {
            getScheduleCRC(device);
        }
    };

    getCurveCRCForAllDevices = () => {
        const {devices} = this.state;
        for (let device of devices) {
            getCurveCRC(device);
        }
    };

    getUploadSoftwareStatusForAllDevices = () => {
        const {devices} = this.state;
        for (let device of devices) {
            getUploadSoftwareStatus(device);
        }
    };

    getConfigurationForAllDevices = () => {
        const {devices} = this.state;
        for (let device of devices) {
            getConfiguration(device);
        }
    }

    render() {
        const {data} = this.state;
        const headers = [
            {
                name: "Adres",
                field: "device.Address",
                valueFormatter: this.addressValueFormatter
            },
            {
                name: "Flagi",
                field: "flags",
                valueFormatter: this.arrayValueFormatter,
                headerComponent: <NRFDataHeaderComponent onClick={this.getUploadSoftwareStatusForAllDevices}/>
            },
            {
                name: "Liczniki",
                field: "counters",
                valueFormatter: this.arrayValueFormatter
            },
            {
                name: "Wersje",
                field: "versions",
                valueFormatter: this.arrayValueFormatter
            },
            {
                name: "Data uruchomienia",
                field: "startTime",
                valueFormatter: this.dateValueFormatter,
                headerComponent: <NRFDataHeaderComponent onClick={this.getConfigurationForAllDevices}/>
            },
            {
                name: "Krzywe CRC",
                field: "curvesCRC",
                valueFormatter: this.joinArrayValueFormatter,
                headerComponent: <NRFDataHeaderComponent onClick={this.getCurveCRCForAllDevices}/>
            },
            {
                name: "Harmonogramy CRC",
                field: "schedulesCRC",
                valueFormatter: this.joinArrayValueFormatter,
                headerComponent: <NRFDataHeaderComponent onClick={this.getScheduleCRCForAllDevices}/>
            },
            {
                name: "Wydajności [impuls/czas]",
                field: "efficiency",
                component: <NRFEfficiencyComponent/>,
                headerComponent: <NRFDataHeaderComponent onClick={this.getEfficiencyForAllDevices}/>
            },
            {
                name: "Status CAN",
                field: "statusCAN",
                component: <NRFDataStatusCANComponent/>,
                headerComponent: <NRFDataHeaderComponent onClick={this.getCanStatusForAllDevices}/>
            },
            {
                name: "",
                field: "device",
                component: <NRFDataButtonComponent/>,
                headerClassName: "button",
                itemClassName: "button"
            }
        ];
        return (
            <Card className="nrf-data">
                <Button icon={<i className="fas fa-arrow-alt-circle-down"/>} onClick={this.onGetAllClick}>Pobierz
                    wszystkie</Button>
                <TableGrid data={data} headers={headers}/>
            </Card>
        );
    }

}

NRFData = connect(state => ({
    farm: state.location.farm,
    shadows: state.shadows.shadows
}))(NRFData)

export default NRFData;