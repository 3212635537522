import React from "react";
import Card from "../../../../components/basics/card/Card";
import LabeledInput from "../../../../components/basics/input/labeled-input/LabeledInput";
import LabeledSelect from "../../../../components/basics/select/labeled-select/LabeledSelect";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import {getAggregatedData} from "../../../../actions/aggregatedActions";
import moment from "moment";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";

export class CageGains extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cage: this.props.devices.filter(item => item.DevType === DevType.CAGE)[0],
            days: 7
        }
    }

    /**
     * Metoda pobiera dane z ostatnich 90 dni
     */
    getAggData() {
        if (this.state.cage) {
            this.props.dispatch(getAggregatedData(this.state.cage, {
                AggDataTimeFrom: moment.utc().startOf("day").subtract(90, "days").toDate().getTime(),
                AggDataTimeTo: moment.utc().startOf("day").toDate().getTime()
            }))
        }
    }

    componentDidMount() {
        this.getAggData();
    }

    onCageChange = value => {
        this.setState({
            cage: value
        })
    };

    onDaysChange = value => {
        this.setState({
            days: +value
        })
    };

    calculateGain() {
        try {
            let cageData = this.props.aggregatedData.get(this.state.cage.DevID);
            cageData.sort((a, b) => a.AggTi - b.AggTi);
            let today = moment.utc().startOf("day");
            let after = today.clone().subtract(this.state.days, "days");
            let dataInRange = cageData.filter(item => item.AggTi >= after.toDate().getTime() && item.AggTi <= today.toDate().getTime());
            if (dataInRange.length > 1) {
                let firstValue = dataInRange[0];
                let lastValue = dataInRange[dataInRange.length - 1];
                let firstWeight = firstValue.AggDt.WeightsOnExits[0];
                let lastWeight = lastValue.AggDt.WeightsOnExits[0];
                return parseFloat(((lastWeight - firstWeight) / moment.utc(lastValue.AggTi).diff(firstValue.AggTi, "days") / 1000).toFixed(1));
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    render() {
        const {cage, days} = this.state;
        let cages = this.props.devices.filter(item => item.DevType === DevType.CAGE).map(cage => ({
            name: cage.getSelectName(),
            value: cage
        }));
        let gain = this.calculateGain();
        return (
            <Card>
                <LabeledSelect label="Klatka separacyjna" options={cages} value={cage} onChange={this.onCageChange}/>
                <LabeledInput label="Ilość dni" type="number" value={days} onChange={this.onDaysChange}/>
                {
                    cage &&
                    <h2>Przyrost: {gain || "-"} kg</h2>
                }
            </Card>
        );
    }

}

export let _CageGains = connect(state => ({
    devices: state.farmDevices.devices,
    aggregatedData: state.aggregatedData.data
}))(CageGains);
export default withRoles({roles: [Roles._SERVICE], showComponent: true})(_CageGains);