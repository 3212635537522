import React from "react";

export default class NRFDataStatusCANComponent extends React.Component {

    render() {
        console.log(this.props);
        const {value} = this.props;
        return (
            <div>
                {
                    value.status.map((status, index) =>
                        <div>
                            {index + 1}: <i className={status ? "fas fa-check fa-fw" : "fas fa-times fa-fw"}/> {value.time[index]}
                        </div>
                    )
                }
            </div>
        );
    }

}