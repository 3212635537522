import React from "react";
import devicesDB from "../../../../database/devicesDB";
import {DevType} from "../../../../constans/devices";
import {connect} from "react-redux";
import {setControlBootloader} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import NewIOT from "../../../../IOT/NewIOT";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import _ from "lodash"
import Button from "../../../../components/basics/button/Button";
import CurrentProgramsButtonComponent from "./CurrentProgramsButtonComponent";
import {groupDevicesByGatewayID} from "../../../../utils/DevicesUtils";

export class CurrentPrograms extends React.Component {

    constructor(props) {
        super(props);
        const {farm} = this.props;
        let multis = devicesDB.getDevices(farm).filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI);
        console.log(multis);
        let groups = groupDevicesByGatewayID(multis);
        if (groups) {
            for (let groupedMultis of [...groups.values()]) {
                NewIOT.startSendingDeviceState(groupedMultis[DevType.DISPENSER_NRF_MULTI]);
            }
        }
        for (let multi of multis) {
            setControlBootloader(multi, 2);
        }
        this.state = {
            multis,
            data: this.createData(multis, props)
        };
    }

    createData(multis, props) {
        const {shadows} = props;
        return multis.map(multi => {
            let shadow = shadows.get(multi.DevID);
            return {
                multi,
                programName:  _.get(shadow, "bootloader.name", "")
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.shadows, nextProps.shadows)) {
            this.setState({
                data: this.createData(this.state.multis, nextProps)
            })
        }
    }

    addressValueFormatter = value => {
        return `0x${value.toString(16).padStart(4, "0").toUpperCase()}`;
    };

    onGetAllClick = () => {
        const {multis} = this.state;
        for (let multi of multis) {
            setControlBootloader(multi, 2);
        }
    }

    render() {
        const {data} = this.state;
        const headers = [
            {
                name: "Nazwa",
                field: "multi.Name"
            },
            {
                name: "Adres",
                field: "multi.Address",
                valueFormatter: this.addressValueFormatter
            },
            {
                name: "Nazwa programu",
                field: "programName"
            },
            {
                name: "",
                component: <CurrentProgramsButtonComponent/>,
                headerClassName: "index",
                itemClassName: "index"
            }
        ]
        return (
            <Card>
                <Button onClick={this.onGetAllClick}>Pobierz wszystkie</Button>
                <TableGrid headers={headers} data={data}/>
            </Card>
        );
    }

}

CurrentPrograms = connect(state => ({
    farm: state.location.farm,
    shadows: state.shadows.shadows
}))(CurrentPrograms);

export default CurrentPrograms;