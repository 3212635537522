import React from "react";
import devicesDB from "../../../../database/devicesDB";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import {getFramRamStatusCRC} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import Button from "../../../../components/basics/button/Button";
import NewIOT from "../../../../IOT/NewIOT";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import Card from "../../../../components/basics/card/Card";
import _ from "lodash"
import RAMToFRAMItemComponent from "./RAMToFRAMItemComponent";
import RAMToFRAMGetAllComponent from "./RAMToFRAMGetAllComponent";

export class RAMToFRAM extends React.Component {

    constructor(props) {
        super(props);

        const {farm} = this.props;
        let multis = devicesDB.getDevices(farm).filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI);
        for (let multi of multis) {
            getFramRamStatusCRC(multi);
            NewIOT.startSendingDeviceState(multi);
        }
        this.state = {
            multis
        }
    }

    getAllData = () => {
        const {multis} = this.state;
        for (let multi of multis) {
            getFramRamStatusCRC(multi);
        }
    };

    addressValueFormatter = value => {
        return `0x${value.toString(16).padStart(4, "0").toUpperCase()}`;
    };

    shadowValueFormatter(devID, path) {
        const {shadows} = this.props;
        let shadow = shadows.get(devID);
        return _.get(shadow, path, [])
    }

    render() {
        const {multis} = this.state;
        const headers = [
            {
                name: "Nazwa",
                field: "Name"
            },
            {
                name: "Adres",
                field: "Address",
                valueFormatter: this.addressValueFormatter
            },
            {
                name: "Krzywe",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "arrCurveST"),
                component: <RAMToFRAMItemComponent/>
            },
            {
                name: "Harmonogramy",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "arrScheduleST"),
                component: <RAMToFRAMItemComponent/>
            },
            {
                name: "Konfiguracja",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "arrCurveConfigST"),
                component: <RAMToFRAMItemComponent/>
            },
            {
                name: "LED",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "arrLEDST"),
                component: <RAMToFRAMItemComponent/>
            },
            {
                name: "Status",
                field: "DevID",
                valueFormatter: value => this.shadowValueFormatter(value, "arrStatusST"),
                component: <RAMToFRAMItemComponent/>
            },
            {
                name: "",
                itemClassName: "index",
                headerClassName: "index",
                component: <RAMToFRAMGetAllComponent/>
            }
        ]
        return (
            <Card>
                <Button onClick={this.getAllData}>Pobierz wszystkie</Button>
                <TableGrid headers={headers} data={multis}/>
            </Card>
        );
    }

}

RAMToFRAM = connect(state => ({
    farm: state.location.farm,
    shadows: state.shadows.shadows
}))(RAMToFRAM);

export default RAMToFRAM;