import React from "react";
import Button from "../../../../../components/basics/button/Button";
import {
    getCANStatus,
    getConfiguration,
    getCurveCRC,
    getFeedEfcImpulse,
    getFeedEfcTime,
    getScheduleCRC,
    getUploadSoftwareStatus
} from "../../../../../IOT/device-functions/DispenserNRFFunctions";

export default class NRFDataButtonComponent extends React.Component {

    onButtonClick = () => {
        const {value} = this.props;
        getUploadSoftwareStatus(value);
        getConfiguration(value);
        getCurveCRC(value);
        getScheduleCRC(value);
        getFeedEfcTime(value);
        getFeedEfcImpulse(value);
        getCANStatus(value);
    };

    render() {
        return (
            <div>
                <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>} style={{margin: 0}}
                        onClick={this.onButtonClick}/>
            </div>
        );
    }

}