import React from "react";
import Button from "../../../../../components/basics/button/Button";

export default class NRFDataHeaderComponent extends React.Component {

    onButtonClick = e => {
        e.stopPropagation();
        const {onClick} = this.props;
        onClick();
    };

    render() {
        const {name} = this.props;
        return (
            <div className="nrf-data-header">
                {name} <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                               onClick={this.onButtonClick}/>
            </div>
        );
    }

}