import React from "react"
import Card from "../../../../components/basics/card/Card";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import {getAsciiClimateTestingData} from "../../../../IOT/device-functions/GatewayFunctions";
import moment from "moment";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import "./_ascii-climate.scss"
import Chart from "../../../../components/basics/chart/Chart";
import _ from "lodash"
import Button from "../../../../components/basics/button/Button";
import {colorHexToRGB, getColorByString} from "../../../../utils/ColorUtils";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import ASCIIClimateCurrentValues, {ModalName as ASCIIClimateCurrentValuesModalName} from "../../../../components/modals-new/ascii-climate-current-values/ASCIIClimateCurrentValues";
import LabeledInput from "../../../../components/basics/input/labeled-input/LabeledInput";
import {Col, Row} from "react-bootstrap";

const humidityKeys = ["wilgotnosc_max", "cisnienie_min", "cisnienie_max", "wilgotnosc"];
const ventilationKeys = [/*"obroty_minimalne ", "opoznienie_went ", "napiecie_klap", */"napiecie klap", "napiecie_przepustnic", "wentylacja_zadana", "went_zadana_przeliczona", "wentylacja_zadana_dynam", "wentylacja_realizowana", "grupa_plynna", "temperatura_srednia"];
const ventilationSettingsKeys = ["nap_min_klap", "nap_max_klap", "nap_min_przepustnic", "nap_max_przepustnic", "wentylacja_min", "wentylacja_max", "ustawienia_temp_zadana", "ustawienia_odchyl_temp_zad", "ustawienia_odchyl_nagrzewu", "ustawienia_pasmo_went", "ustawienia_went_min", "ustawienia_odchyl_went_min", "ustawienia_went_max", "liczba_kominow", "liczba_masterow", "obroty_minimalne", "wentylacja_zadana", "czas_cyklu_przewietrzania", "dynamika_obiektu", "dzien_krzywej", "intensywnosc_przewietrzania", "tryb_pracy", "wentylacja_reczna", "opoznienie_went"];
const heatersKeys = ["czas_pracy_nagrzewnic", "czas_przerwy_nagrzewnic"];
const temperatureKeys = ["temperatura_zadana", "temperatura_zadana_przesunieta", "temperatura_srednia", "temperatura_1", "temperatura_2", "temperatura_3", "temperatura_4", "temperatura_5"];
const alarmsKeys = ["ust_odchyl_alarm_min", "ust_odchyl_alarm_max", "ustawienia_alarm_bezwzgl"];
const coolingKeys = ["temp_wlacz_chlodzenia", "czas_pracy_chlodzenia", "czas_przerwy_chlodzenia"];

export class ASCIIClimate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gateway: null,
            start: moment().startOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL),
            stop: moment().endOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL),
            granularity: 0,
            timeout: null,
            params: [],
            logs: [],
            headers: [],
            chartData: [],
            referenceLines: [],
            humidityOn: false,
            ventilationOn: false,
            ventilationSettingsOn: false,
            heatersOn: false,
            temperatureOn: false,
            alarmsOn: false,
            coolingOn: false,
            notDeclaredOn: false,
            moreInfo: [],
            lastClickedTime: null,
            sent: false,
            logsFile: [],
            dataFile: [],
            notDeclared: [],
            ticks: [],
            domain: [moment().startOf("day").toDate().getTime(), "dataMax"]
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    onAsciiClimateTestingDataSuccess = msg => {
        try {
            const {CAnsw: {data: {params, logs}}} = msg;
            this.setState({
                params,
                logs,
                ...this.getChartData(params),
                referenceLines: []
            })
        } catch (e) {
            this.setState({
                params: [],
                logs: [],
                headers: [],
                chartData: [],
                referenceLines: []
            })
        }
    };


    isDark(color) {
        let rgb = colorHexToRGB(color);
        let luma = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
        return luma < 50;
    }

    lightenColor(color, percent = 30) {
        let num = parseInt(color, 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = (num >> 8 & 0x00FF) + amt,
            G = (num & 0x0000FF) + amt;

        return (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
    }

    getCurrentChartStatusForKey(key) {
        if (humidityKeys.includes(key)) return this.state.humidityOn;
        if (ventilationKeys.includes(key)) return this.state.ventilationOn;
        if (ventilationSettingsKeys.includes(key)) return this.state.ventilationSettingsOn;
        if (heatersKeys.includes(key)) return this.state.heatersOn;
        if (temperatureKeys.includes(key)) return this.state.temperatureOn;
        if (alarmsKeys.includes(key)) return this.state.alarmsOn;
        if (coolingKeys.includes(key)) return this.state.coolingOn;
        return false;
    }

    isInDeclaredKeys(key) {
        if (humidityKeys.includes(key)) return true;
        if (ventilationKeys.includes(key)) return true;
        if (ventilationSettingsKeys.includes(key)) return true;
        if (heatersKeys.includes(key)) return true;
        if (temperatureKeys.includes(key)) return true;
        if (alarmsKeys.includes(key)) return true;
        return coolingKeys.includes(key);
    }

    getChartData(params) {
        let notDeclared = [];
        let data = [];
        for (let item of params) {
            // let dataObj = data.find(dataItem => moment(item.InsertionTime).format(moment.HTML5_FMT.DATETIME_LOCAL) === moment(dataItem.name).format(moment.HTML5_FMT.DATETIME_LOCAL));
            let d = {
                name: item.InsertionTime,
                time: item.InsertionTime
            };
            for (let key in item.Data) {
                if (!this.isInDeclaredKeys(key) && !notDeclared.includes(key)) {
                    notDeclared.push(key);
                }
                d[key] = item.Data[key];
            }
            // if (!dataObj) {
            data.push(d);
            // }
        }
        return {
            chartData: data,
            notDeclared
        }
    }

    setData(name, value) {
        this.setState({
            [name]: value
        }, () => {
            this.sendData();
        })
    };

    sendData() {
        const {gateway, start, stop, granularity} = this.state;
        if (gateway && start && stop && granularity !== undefined) {
            clearTimeout(this.state.timeout);

            let timeout = setTimeout(() => {
                getAsciiClimateTestingData(gateway, moment(start).toDate().getTime(), moment(stop).toDate().getTime(), +granularity, this.onAsciiClimateTestingDataSuccess);
            }, 100);
            this.setState({
                timeout,
                sent: true
            });
        }
    }

    getClosestTimestamp(time) {
        const {chartData} = this.state;
        return chartData.reduce((prev, curr) => Math.abs(curr.time - time) < Math.abs(prev - time) ? curr.time : prev, 0);
    }

    onRowClick = (data, index) => {
        let referenceLines = this.state.referenceLines.slice(0);
        if (referenceLines.find(item => item.InsertionTime === data.InsertionTime)) {
            referenceLines = referenceLines.filter(item => item.InsertionTime !== data.InsertionTime);
        } else {
            referenceLines.push({...data, index});
        }
        this.setState({
            referenceLines
        });
    };

    onTurnOnValues(keys, name) {
        let headers = this.state.headers.slice(0);
        const currentStatus = this.state[name];
        for (let key of keys) {
            if (currentStatus) {
                headers = headers.filter(item => item.dataKey !== key);
            } else {
                let color = getColorByString(key);
                let isDark = this.isDark(color);
                console.log(key, color, isDark);
                if (isDark) {
                    color = getColorByString(key + "123"); // zmiana klucza przez dopisanie czegoś, tak aby hash tworzony różnil sie znacząco od innych (głównie dla temperatura_1, temperatura_2, itp.)
                }
                if (key === "went_zadana_przeliczona") {
                    color = this.lightenColor(color);
                }
                console.log(color);
                headers.push({
                    dataKey: key,
                    name: key,
                    color: `#${color}`,
                    type: "stepAfter"
                })
            }
        }
        this.setState({
            headers,
            [name]: !currentStatus
        }, () => {
            if (this.state.lastClickedTime) {
                this.setState({
                    moreInfo: this.getMoreInfo(this.state.lastClickedTime)
                })
            }
        })
    }

    dateValueFormatter = value => moment(value).format("DD.MM.YYYY HH:mm:ss");

    getCurrentOnKeys() {
        let keys = [];
        const {humidityOn, ventilationOn, ventilationSettingsOn, heatersOn, temperatureOn, alarmsOn, coolingOn} = this.state;
        if (humidityOn) keys = [...keys, ...humidityKeys];
        if (ventilationOn) keys = [...keys, ...ventilationKeys];
        if (ventilationSettingsOn) keys = [...keys, ...ventilationSettingsKeys];
        if (heatersOn) keys = [...keys, ...heatersKeys];
        if (temperatureOn) keys = [...keys, ...temperatureKeys];
        if (alarmsOn) keys = [...keys, ...alarmsKeys];
        if (coolingOn) keys = [...keys, ...coolingKeys];
        return keys;
    }

    getLastValueForKey(key, lastTime) {
        const {params} = this.state;
        let data = params.filter(item => item.InsertionTime <= lastTime && item.Data[key] !== undefined);
        data.sort((a, b) => (b.InsertionTime - a.InsertionTime));
        let tmp = data[0];
        if (tmp) {
            return {
                time: tmp.InsertionTime,
                value: tmp.Data[key]
            }
        }
        return {
            time: "-",
            value: "-"
        }
    }

    getMoreInfo(lastTime) {
        let keysToShow = this.getCurrentOnKeys();
        return keysToShow.map(key => {
            return {
                key,
                ...this.getLastValueForKey(key, lastTime)
            }
        });
    }

    onChartClick = (data) => {
        if (data) {
            let payload = data.activePayload.find(item => item.payload.name === data.activeLabel).payload;
            console.log(payload);
            let moreInfo = this.getMoreInfo(payload.name);
            this.setState({
                moreInfo,
                lastClickedTime: payload.name
            })
        }
    };

    onShowCurrentValuesClick = () => {
        this.props.show(ASCIIClimateCurrentValuesModalName, {});
    };

    onLogsFileChange = e => {
        const {start, stop} = this.state;
        let fileList = e.target.files;
        let file = fileList[0];
        console.log(file);
        let reader = new FileReader();
        reader.onload = () => {
            let result = reader.result;
            let splitted = result.split("\n");
            splitted.filter(item => !!item);
            let logs = splitted.slice(0, splitted.length - 1).map(item => {
                return JSON.parse(item)
            }).sort((a, b) => b.InsertionTime - a.InsertionTime);
            let obj = {
                logsFile: logs
            };
            if (start && stop) {
                let filtered = logs.filter(item => item.InsertionTime >= moment(start).toDate().getTime() && item.InsertionTime <= moment(stop).toDate().getTime());
                obj = {
                    ...obj,
                    logs: filtered
                }
            }
            console.log(obj);
            this.setState(obj)
        };
        reader.readAsText(file);
    };

    onDataFileChange = e => {
        const {start, stop} = this.state;
        let fileList = e.target.files;
        let file = fileList[0];
        let reader = new FileReader();
        reader.onload = () => {
            let result = reader.result;
            let splitted = result.split("\n");
            splitted.filter(item => !!item);
            let data = splitted.slice(0, splitted.length - 1).map(item => {
                return JSON.parse(item)
            }).sort((a, b) => a.InsertionTime - b.InsertionTime);
            let obj = {
                dataFile: data
            };
            if (start && stop) {
                let diff = moment(stop).diff(start, "seconds");
                let ticks = [];
                for (let i = 0; i < diff; i++) {
                    ticks.push(moment(start).add(i, "seconds").toDate().getTime());
                }
                let dataFiltered = data.filter(item => item.InsertionTime >= moment(start).toDate().getTime() && item.InsertionTime <= moment(stop).toDate().getTime());
                obj = {
                    ...obj,
                    params: dataFiltered,
                    ...this.getChartData(dataFiltered),
                    referenceLines: [],
                    ticks,
                    domain: [moment(start).toDate().getTime(), "dataMax"]
                }
            }
            this.setState(obj)
        };
        reader.readAsText(file);
    };

    onChangeFilter(value, name) {
        this.setState({
            [name]: value
        })
    }

    onApplyFilter = () => {
        const {start, stop, dataFile, logsFile} = this.state;
        if (start && stop) {
            let diff = moment(stop).diff(start, "seconds");
            let ticks = [];
            for (let i = 0; i < diff; i++) {
                ticks.push(moment(start).add(i, "seconds").toDate().getTime());
            }
            let dataFiltered = dataFile.filter(item => item.InsertionTime >= moment(start).toDate().getTime() && item.InsertionTime <= moment(stop).toDate().getTime());
            let logsFiltered = logsFile.filter(item => item.InsertionTime >= moment(start).toDate().getTime() && item.InsertionTime <= moment(stop).toDate().getTime());
            this.setState({
                params: dataFiltered,
                ...this.getChartData(dataFiltered),
                referenceLines: [],
                logs: logsFiltered,
                ticks,
                domain: [moment(start).toDate().getTime(), "dataMax"]
            })
        }
    };

    render() {
        // const {gateways} = this.props;
        const {
            /*gateway, */start, stop, /*granularity,*/ logs, headers, chartData, referenceLines, humidityOn,
            ventilationOn, ventilationSettingsOn, heatersOn, temperatureOn, alarmsOn, coolingOn, moreInfo,
            sent, notDeclared, notDeclaredOn, ticks, domain
        } = this.state;
        const tableHeaders = [
            {
                name: "Data",
                field: "InsertionTime",
                valueFormatter: this.dateValueFormatter,
                headerClassName: "index",
                itemClassName: "index"
            },
            {
                name: "Log",
                field: "Log"
            }
        ];
        let moreInfoHeaders = [
            {
                name: "Klucz",
                field: "key"
            },
            {
                name: "Data",
                field: "time",
                valueFormatter: value => value === "-" ? value : moment(value).format("DD.MM.YYYY HH:mm:ss.SSS")
            },
            {
                name: "Wartość",
                field: "value"
            }
        ];
        return (
            <div className="ascii-climate">
                {/*<Card>*/}
                {/*    <LabeledSelect label={"Gateway"} options={gateways.map(gw => ({*/}
                {/*        name: gw.Name,*/}
                {/*        value: gw*/}
                {/*    }))} value={gateway} onChange={value => this.setData("gateway", value)}/>*/}
                {/*    <Row>*/}
                {/*        <Col lg={4}>*/}
                {/*            <LabeledInput label={"Start"} type={"datetime-local"}*/}
                {/*                          onChange={value => this.setData("start", value)} value={start}/>*/}
                {/*        </Col>*/}
                {/*        <Col lg={4}>*/}
                {/*            <LabeledInput label={"Stop"} type={"datetime-local"}*/}
                {/*                          onChange={value => this.setData("stop", value)} value={stop}/>*/}
                {/*        </Col>*/}
                {/*        <Col lg={4}>*/}
                {/*            <LabeledInput label={"Ziarnistość"} type={"number"}*/}
                {/*                          onChange={value => this.setData("granularity", value)} value={granularity}/>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Card>*/}
                <Card>
                    <LabeledInput label={"Plik logi (ASCIICLDebug)"} type={"file"} frName="Załaduj" id="logs"
                                  onChange={this.onLogsFileChange}/>
                    <LabeledInput label={"Plik dane (ASCIICLData)"} type={"file"} frName="Załaduj" id="data"
                                  onChange={this.onDataFileChange}/>
                    <Row>
                        <Col lg={6}>
                            <LabeledInput label={"Od"} type={"datetime-local"} value={start}
                                          onChange={val => this.onChangeFilter(val, "start")}/>
                        </Col>
                        <Col lg={6}>
                            <LabeledInput label={"Do"} type={"datetime-local"} value={stop}
                                          onChange={val => this.onChangeFilter(val, "stop")}/>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <Col lg={2} className="d-flex justify-content-end">
                            <Button buttonColor={"success"} onClick={this.onApplyFilter}>Zastosuj</Button>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <h4>#</h4>
                    <TableGrid data={logs} headers={tableHeaders} shouldIndex showPagination paginationItems={20}
                               onRowClick={this.onRowClick}/>
                    <h4>$</h4>
                    <Button onClick={() => this.onTurnOnValues(humidityKeys, "humidityOn")}
                            buttonColor={humidityOn ? "success" : ""}>Wilgotność</Button>
                    <Button onClick={() => this.onTurnOnValues(ventilationKeys, "ventilationOn")}
                            buttonColor={ventilationOn ? "success" : ""}>Wentylacja</Button>
                    <Button onClick={() => this.onTurnOnValues(ventilationSettingsKeys, "ventilationSettingsOn")}
                            buttonColor={ventilationSettingsOn ? "success" : ""}>Ustawienia wentylacji</Button>
                    <Button onClick={() => this.onTurnOnValues(heatersKeys, "heatersOn")}
                            buttonColor={heatersOn ? "success" : ""}>Nagrzewnice</Button>
                    <Button onClick={() => this.onTurnOnValues(temperatureKeys, "temperatureOn")}
                            buttonColor={temperatureOn ? "success" : ""}>Temperatura</Button>
                    <Button onClick={() => this.onTurnOnValues(alarmsKeys, "alarmsOn")}
                            buttonColor={alarmsOn ? "success" : ""}>Alarmy</Button>
                    <Button onClick={() => this.onTurnOnValues(coolingKeys, "coolingOn")}
                            buttonColor={coolingOn ? "success" : ""}>Chłodzenie</Button>
                    {
                        notDeclared.length > 0 &&
                        <Button buttonColor={notDeclaredOn ? "success" : ""}
                                onClick={() => this.onTurnOnValues(notDeclared, "notDeclaredOn")}>Brak grupy
                            ({notDeclared.length})</Button>
                    }
                    <div style={{height: "50rem"}}>
                        <Chart
                            refreshHideOnNewChartDef={true}
                            Yaxis={{
                                name: "Wartość"
                            }} Xaxis={{
                            name: "Czas",
                            dataKey: "name",
                            formatter: this.dateValueFormatter,
                            ticks,
                            type: "number",
                            domain
                        }} dataDef={headers} data={chartData} legendHeight={null}
                            referenceLines={referenceLines.map(line => ({
                                x: this.getClosestTimestamp(line.InsertionTime),
                                name: line.index + 1
                            }))}
                            tooltipLabelFormatter={this.dateValueFormatter}
                            brush={{brushKey: "name", tickFormatter: this.dateValueFormatter}}
                            onChartClick={this.onChartClick}/>
                    </div>
                    <h4>Dodatkowe informacje</h4>
                    <TableGrid data={moreInfo} headers={moreInfoHeaders} shouldIndex/>
                </Card>
                <ButtonGroup fixed>
                    <Button buttonStyle={"round"} icon={<i className="far fa-play-circle"/>}
                            onClick={this.onShowCurrentValuesClick}/>
                    {
                        sent &&
                        <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-redo"/>}
                                onClick={() => this.sendData()}/>
                    }
                </ButtonGroup>
                <ASCIIClimateCurrentValues/>
            </div>
        );
    }

}

ASCIIClimate = connect(
    state => ({
        gateways: state.farmDevices.devices.filter(item => item.DevType === DevType.GATEWAY)
    }),
    dispatch => ({
        dispatch,
        ...bindActionCreators({show}, dispatch)
    })
)(ASCIIClimate);

export default ASCIIClimate;