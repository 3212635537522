import React from "react";
import Button from "../../../../components/basics/button/Button";
import {getFramRamStatusCRC} from "../../../../IOT/device-functions/DispenserNRFFunctions";

export default class RAMToFRAMGetAllComponent extends React.Component {

    onButtonClick = () => {
        const {value} = this.props;
        getFramRamStatusCRC(value);
    }

    render() {
        return (
            <div>
                <Button buttonStyle={"round"} icon={<i className="fas fa-arrow-alt-circle-down"/>}
                        onClick={this.onButtonClick}/>
            </div>
        );
    }

}