import React from "react";

export default class NRFEfficiencyComponent extends React.Component {

    getIcon(value) {
        if (value) return <i className="fas fa-check fw-fw"/>
        return <i className="fas fa-times fw-fw"/>
    }

    render() {
        const {value} = this.props;
        return (
            <div>
                {
                    value.map(item =>
                        <div key={item.nr}>
                            {item.nr}: {item.feedOverImpulse} {this.getIcon(item.setOverImpulse)}/{item.feedOverTime} {this.getIcon(item.setOverTime)}
                        </div>
                    )
                }
            </div>
        );
    }

}