import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../../../../components/basics/card/Card";
import LabeledInput from "../../../../components/basics/input/labeled-input/LabeledInput";
import Button from "../../../../components/basics/button/Button";
import Animal from "../../../../beans/Animal";
import testLokiDB from "../../../../database/testLokiDB";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import {Col, Row} from "react-bootstrap";
import {db as lokiDB} from "../../../../database/lokiDB";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class TestLoki extends Component {

    state = {
        amount: 1000,
        objects: [],
        generateTime: 0,
        insertTime: 0,
        lastInsertedObjectsAmount: 0,
        insertTimeNoCheck: 0,
        lastInsertedObjectsAmountNoCheck: 0,
        getAllTime: 0,
        objectsInLoki: testLokiDB.getAll().length,
        currentLokiSize: [],
        overallLokiSize: 0,
        indexedDBSize: 0
    };

    async componentDidMount() {
        let data = [];
        for (let collection of lokiDB.collections) {
            let size = this.getSize(collection);
            data.push({
                name: collection.name,
                count: collection.count(),
                size: this.formatBytes(size)
            })
        }
        let storageEstimate = await navigator.storage.estimate();
        this.setState({
            currentLokiSize: data,
            overallLokiSize: JSON.stringify(lokiDB).length,
            indexedDBSize: this.formatBytes(storageEstimate.usageDetails.indexedDB)
        })
    }

    onAmountChange = value => {
        this.setState({
            amount: +value
        })
    };

    onGenerateClick = () => {
        let timeStart = new Date().getTime();
        const {amount, objects} = this.state;
        const {farm} = this.props;
        let obj = [];
        for (let i = 0; i < amount; i++) {
            obj.push(Animal.createAnimal(Math.floor((Math.random() * 10000) + 1), Math.floor((Math.random() * 4) + 1), 1, farm, "qweasdzxc"));
        }
        this.setState({
            objects: [...objects, ...obj],
            generateTime: new Date().getTime() - timeStart
        })
    };

    insertToLoki = async () => {
        const {objects} = this.state;
        let timeStart = new Date().getTime();
        await testLokiDB.insertIntoTest(objects);
        this.setState({
            insertTime: new Date().getTime() - timeStart,
            lastInsertedObjectsAmount: objects.length
        }, () => {
            this.setState({
                objectsInLoki: testLokiDB.getAll().length
            })
        })
    };

    insertToLokiWithoutCheck = () => {
        const {objects} = this.state;
        let timeStart = new Date().getTime();
        testLokiDB.onlyInsert(objects);
        this.setState({
            insertTimeNoCheck: new Date().getTime() - timeStart,
            lastInsertedObjectsAmountNoCheck: objects.length
        }, () => {
            this.setState({
                objectsInLoki: testLokiDB.getAll().length
            })
        })
    };

    clearMemoryObjects = () => {
        this.setState({
            objects: []
        })
    };

    getAllObjects = () => {
        let timeStart = new Date().getTime();
        testLokiDB.getAll();
        this.setState({
            getAllTime: new Date().getTime() - timeStart
        });
    };

    clearLoki = () => {
        testLokiDB.clear();
        this.setState({
            objectsInLoki: 0
        })
    };

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getSize(collection) {
        let items = collection.find();
        return JSON.stringify(items).length - 2; // - 2 zeby wyeliminowac tablice
    }

    renderLokiData() {
        const {currentLokiSize} = this.state;
        let array = [];
        for (let i = 0; i < currentLokiSize.length; i += 3) {
            let firstItem = currentLokiSize[i];
            let secondItem = currentLokiSize[i + 1];
            let thirdItem = currentLokiSize[i + 2];
            array.push(
                <Row>
                    <Col lg={4} style={{borderBottom: "1px solid black"}}>
                        <div>Nazwa: {firstItem.name}</div>
                        <div>Ilość rekordów: {firstItem.count}</div>
                        <div>Rozmiar: {firstItem.size}</div>
                    </Col>
                    {
                        secondItem &&
                        <Col lg={4} style={{borderBottom: "1px solid black"}}>
                            <div>Nazwa: {secondItem.name}</div>
                            <div>Ilość rekordów: {secondItem.count}</div>
                            <div>Rozmiar: {secondItem.size}</div>
                        </Col>
                    }
                    {
                        thirdItem &&
                        <Col lg={4} style={{borderBottom: "1px solid black"}}>
                            <div>Nazwa: {thirdItem.name}</div>
                            <div>Ilość rekordów: {thirdItem.count}</div>
                            <div>Rozmiar: {thirdItem.size}</div>
                        </Col>
                    }
                </Row>
            )
        }
        return array;
    }

    render() {
        const {
            amount, generateTime, objects, insertTime, lastInsertedObjectsAmount, getAllTime,
            insertTimeNoCheck, lastInsertedObjectsAmountNoCheck, objectsInLoki, overallLokiSize, indexedDBSize
        } = this.state;
        return (
            <>
                <Card>
                    <div>
                        <LabeledInput label={"Ilość obiektów"} type={"number"} value={amount}
                                      onChange={this.onAmountChange}/>
                    </div>
                    <div>
                        <div>
                            <ButtonGroup>
                                <Button onClick={this.onGenerateClick}>
                                    Generuj obiekty
                                </Button>
                                <Button onClick={this.insertToLoki}>
                                    Dodaj ze sprawdzaniem czy istnieją
                                </Button>
                                <Button onClick={this.insertToLokiWithoutCheck}>
                                    Dodaj bez sprawdzania czy istnieją
                                </Button>
                                <Button onClick={this.getAllObjects}>
                                    Pobierz wszystkie obiekty z lokiego
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div>
                            <ButtonGroup>
                                <Button onClick={this.clearMemoryObjects}>
                                    Wyczyść obiekty w pamięci
                                </Button>
                                <Button onClick={this.clearLoki}>
                                    Wyczyść lokiego
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div>
                        Czas generowania obiektów: {generateTime} ms
                    </div>
                    <div>
                        Ilość obiektów w pamięci: {objects.length}
                    </div>
                    <hr/>
                    <div>
                        Ilość obiektów w lokim: {objectsInLoki}
                    </div>
                    <hr/>
                    <Row>
                        <Col lg={6}>
                            <h6>Sprawdzanie</h6>
                            <div>
                                Czas wstawiania elementów do lokiego: {insertTime} ms
                            </div>
                            <div>
                                Ostatnia ilość wstawionych obiektów: {lastInsertedObjectsAmount}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <h6>Bez sprawdzania</h6>
                            <div>
                                Czas wstawiania elementów do lokiego: {insertTimeNoCheck} ms
                            </div>
                            <div>
                                Ostatnia ilość wstawionych obiektów: {lastInsertedObjectsAmountNoCheck}
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <div>
                        Czas pobrania wszystkich rekordów: {getAllTime} ms
                    </div>
                </Card>
                <Card>
                    <h6>Aktualne rozmiary lokiego:</h6>
                    {this.renderLokiData()}
                    <hr/>
                    <h6>Rozmiar danych z lokiego: {this.formatBytes(overallLokiSize)}</h6>
                    <h6>Rozmiar IndexedDB wg przeglądarki: {indexedDBSize}</h6>
                </Card>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
)(TestLoki);