import React from "react";
import Card from "../../../../components/basics/card/Card";
import LabeledSelect from "../../../../components/basics/select/labeled-select/LabeledSelect";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import {getQueuesLastSuccess} from "../../../../IOT/device-functions/GatewayFunctions";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import moment from "moment";

export class BackendQueueSuccess extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gateway: null,
            devices: props.devices.filter(item => item.DevType === DevType.GATEWAY).map(item => ({
                name: item.Name,
                value: item
            })),
            queues: []
        }
    }

    onGetQueuesSuccess = msg => {
        let queues = [];
        for (let key in msg.CAnsw.queues) {
            queues.push({
                name: key,
                value: msg.CAnsw.queues[key]
            })
        }
        this.setState({
            queues
        })
    };

    onGetQueuesFailed = () => {
        this.setState({
            queues: []
        })
    }

    onSelectChange = value => {
        this.setState({
            gateway: value
        });
        getQueuesLastSuccess(value, this.onGetQueuesSuccess, this.onGetQueuesFailed)
    };

    dateValueFormatter = value => {
        if (value) {
            return moment(value).format("DD.MM.YYYY HH:mm");
        }
    }

    render() {
        const {gateway, devices, queues} = this.state;
        const headers = [
            {
                name: "Kolejka",
                field: "name"
            },
            {
                name: "Ostatni sukces",
                field: "value",
                valueFormatter: this.dateValueFormatter
            }
        ]
        return (
            <Card>
                <LabeledSelect label={"Gateway"} options={devices} value={gateway} onChange={this.onSelectChange}/>
                <TableGrid data={queues} headers={headers}/>
            </Card>
        );
    }

}

BackendQueueSuccess = connect(state => ({
    devices: state.farmDevices.devices
}))(BackendQueueSuccess);

export default BackendQueueSuccess;